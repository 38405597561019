import styled from "styled-components"
import { Link } from "gatsby"
import colors from "../../constants/colors"
import dimensions from "../../constants/dimensions"
import breakpoints from "../../constants/breakpoints"
import { spacing } from "../../constants/spacing"
import { css } from "styled-components"

// general styling applied to the header / navigation
export const NavStyle = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.vcp};
  z-index: 9999;
  position: fixed;
  top: 0;
  width: 100%;  
  // height: ${dimensions.headerHeight}px; 
  border-style: none none solid none;
  border-color: hsl(0, 0%, 80%);
  border-width: 1px;
`

// wrapper for the nav, sets header height & max-width
export const NavWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

   // ${dimensions.headerHeight}px;
  width: 100vw;
  max-width: ${dimensions.maxWidth}px;
`

// the logo
export const Logo = styled(Link)`
  width: 2.7rem;
  margin: 6px 12px 6px 12px;
  z-index: 12;

  @media (max-width: ${breakpoints.medium}px) {
    // display: none;
  }
`

// the page title (Gau Alt-Burgund)
export const PageTitle = styled(Link)`
  color: black;
  font-family: formalScript;
  font-size: 1.5rem;
  text-decoration: none;

  //Hover
  &:hover,
  &:focus {
    color: ${colors.burgund};
  }
`

// container for all pagelinks
export const PageLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  @media (max-width: ${breakpoints.medium}px) {
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin-right: 0;

    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`

export const MenuElement = styled.div`
  .none {
    display: none;

    @media (max-width: ${breakpoints.medium}px) {
      display: flex;
    }
  }

  &:hover,
  &:focus {
    .none {
      display: flex;

      @media (max-width: ${breakpoints.medium}px) {
        display: flex;
      }
    }
  }
`

export const HoverCatch = styled.div`
  position: absolute;
  width: 150px;
  height: 200px;

  @media (max-width: ${breakpoints.medium}px) {
    display: none !important;
  }
`

// a pagelink
export const PageLink = styled(Link)`
  margin: 10px 0 10px 0;
  font-size: 1rem;
  text-decoration: none;
  color: white;

  @media (min-width: ${breakpoints.medium}px) {
    margin: 0 15px 0 15px;
  }

  //Hover
  &:hover,
  &:focus {
    & span {
      padding-bottom: 0;
      border-bottom: none;
      color: ${colors.burgund};

      @media (min-width: ${breakpoints.medium}px) {
        padding-bottom: 5px;
        border-bottom: 2px solid rgba(255, 255, 255, 0.3);
        color: white;
      }
    }
  }

  ${props =>
    props.hasSubmenu &&
    css`
      // color: red;
    `}
`

export const DropdownArrow = styled.div`
  position: absolute;
  margin-top: 33px;
  z-index: 2;
  // to flex when hover

  background-color: #fff;
  border-radius: 4px 0 0 0;
  box-shadow: rgba(82, 95, 127, 0.04) -3px -3px 5px;
  box-sizing: border-box;
  height: 12px;

  transform: rotate(45deg);
  width: 12px;

  @media (max-width: ${breakpoints.medium}px) {
    display: none !important;
  }
`

export const Dropdown = styled.div`
  position: absolute;
  margin-top: 38px;
  z-index: 3;

  // to flex when hover
  display: none;
  justify-content: center;

  background-color: ${colors.vcp};
  -webkit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);

  // if blur is supported (not in firefox)
  @supports (backdrop-filter: blur(5px)) {
    background-color: hsla(211, 100%, 30%, 62.5%);
    backdrop-filter: blur(5px);
  }

  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.78);
  border-radius: 8px;
  // color: white;
  border-width: 1px;
  border-color: #aaaaaa;
  // border-style: solid;

  ul {
    list-style: none;
    margin: 0;
    padding: 0.7rem 0;

    li {
      padding: 0.7rem 1.4rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: hsla(211, 100%, 42%, 100%);
        // text-decoration: underline dashed hsl(211, 100%, 10%);
      }
    }
  }

  // on mobile, dropdown always shown
  // and remove shadow etc.
  @media (max-width: ${breakpoints.medium}px) {
    position: relative;
    margin-top: 0;

    box-shadow: none;
    background-color: transparent;

    ul {
      padding: 0;

      li {
        padding-left: 1.5rem;
      }
    }
  }
`

export const DropdownLink = styled(Link)`
  text-decoration: none;
  color: white; //black;

  display: flex;

  &:before {
    content: "»";
    padding-right: ${spacing.space2xs};
    vertical-align: -50%;
    line-height: 1.2rem;
  }

  &:visited {
    color: white;
  }
`

// hamburger icon to open the mobile menu
export const Hamburger = styled.img`
  display: flex;
  border-style: none;
  padding: 0;
  width: 30px;
  height: 20px;
  margin: 1rem;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.medium}px) {
    margin-right: 0 20px 0 0;
  }
`

// TODO
/*

@font-face {
  font-family: "Formal Script 421 BT";
  src: url(formalScript.ttf);
}
font-family: "Formal Script 421 BT", arial;*/
