import React, { Fragment } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import GlobalStyle from '../GlobalStyle';
import styled from 'styled-components';
import dimensions from '../../constants/dimensions';
import Meta from './META';

export const Content = styled.div`
  min-height: calc(
    100vh - ${dimensions.headerHeight}px - ${dimensions.footerHeight}px
  );
  padding-top: ${dimensions.headerHeight}px;
`;

// eslint-disable-next-line
export default function Layout({ title, description, children }) {
  return (
    <Fragment>
      <Meta title={title} description={description} />
      <GlobalStyle />
      <Header />
      <Content role={'main'}>{children}</Content>
      <Footer />
    </Fragment>
  );
}
