const colors = {
  /* primary color */
  burgund: "#b00000",
  burgundLight: "rgb(176, 0, 0, 0.15)",
  burgund2: "rgb(176, 0, 0, 0.45)",
  burgundHoverLight: "rgb(176, 0, 0, 0.04)",

  /* secondary color */
  vcp: "#004f9f",
  vcpLight: "hsl(210, 100%, 51%)",

  /* UI colors */
  // grey
  grey: "#8d97ad",
  grey2: "rgb(200, 200, 200, 0.5)",
  greySecondaryText: "rgb(0, 0, 0, 0.8)",
  warmGrey: "#ddd",
  greyUiBackground: "rgb(0, 0, 0, 0.04)",

  // blue
  blueLink: "#004f9f",
  blueButton: "#3f51b5",
  blueButtonBackground: "rgb(0,0,256,0.1)",
  blue: "hsla(210, 100%, 9%, 70%)",

  // greys
  greys: {
    900: "hsl(0, 0%, 6%)",
    800: "hsl(0, 0%, 17%)",
    700: "hsl(0, 0%, 28%)",
    600: "hsl(0, 0%, 39%)",
    500: "hsl(0, 0%, 50%)",
    400: "hsl(0, 0%, 61%)",
    300: "hsl(0, 0%, 72%)",
    200: "hsl(0, 0%, 83%)",
    100: "hsl(0, 0%, 94%)",
  },

  // indigo
  indigo: {
    dark: "hsl(209, 86%, 25%)",
    light: "hsl(209, 86%, 55%)",
    hover: "hsl(209, 86%, 18%)",
  },

  // links
  links: {
    default: "hsl(218, 76%, 52%)",
    dark: "hsl(218, 76%, 42%)",
  },
}

export default colors
