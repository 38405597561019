import { createGlobalStyle } from "styled-components"
import colors from "../constants/colors"

// Load Permanent Marker typeface
require("typeface-permanent-marker")
require("typeface-open-sans")

const formalScript = require("../assets/formalScript.ttf")

const GlobalStyle = createGlobalStyle`

// Global Styles

// Parts
body {
    margin: 0;
    padding: 0;
    height: 100%;
    color: black;
    background-color: white;
    font-family: 'Open Sans', sans-serif;
}

h2 {
    color: ${colors.burgund};
}

h3 {
    font-weight: 300;
    color: ${colors.burgund};
}

img {
    max-width: 100%;
}

 @font-face {
    font-family: formalScript;
    src: url(${formalScript});
  }


// Fonts
.permanent-marker {
    font-family: 'Permanent Marker', sans-serif;
}



`

export default GlobalStyle
