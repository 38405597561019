// imports
import React, { useEffect, useState } from "react"
import {
  Logo,
  NavStyle,
  NavWrapper,
  PageTitle,
  PageLinkContainer,
  PageLink,
  Hamburger,
  Dropdown,
  DropdownArrow,
  MenuElement,
  HoverCatch,
  DropdownLink,
} from "./styled"
import dimensions from "../../constants/dimensions"
import breakpoints from "../../constants/breakpoints"

// constants
const logo = require("../../images/gab-logo.png")
const hamburger = require("../../images/hamburger.png")

// header component
// see ./styled for more information about the components used here
export default function Header() {
  const [mobileMenu, setMobileMenu] = useState(true)
  const [open, setOpen] = useState(false)

  // add event listener to the resize event and initialize values
  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
  }, [])

  // close menu on resize, set header height, set mobileMenu state
  function handleResize() {
    console.log("handle Resize")
    if (window.innerWidth < breakpoints.medium) {
      setMobileMenu(true)
      setOpen(false)
      document.getElementById("nav-wrapper").style.height = !open
        ? dimensions.headerHeight.toString() + "px"
        : "350px"
    } else {
      setMobileMenu(false)
      setOpen(true)
      document.getElementById("nav-wrapper").style.height =
        dimensions.headerHeight.toString() + "px"
    }
  }

  // menu on off
  const toggleMenu = () => {
    console.log("toggle menu")
    document.getElementById("nav-wrapper").style.height = open
      ? dimensions.headerHeight.toString() + "px"
      : "350px"
    document.getElementById("nav-wrapper").style.flexDirection = open
      ? "row"
      : "column"
    setOpen(open => !open)
  }

  return (
    // header and nav are structural components to comply with the html5 standard
    <header role="banner">
      <nav>
        {/* actual content follows here: */}
        {/* holds header space, beacuse header ix fixed */}
        <NavStyle>
          <NavWrapper
            id="nav-wrapper"
            style={{
              flexDirection: mobileMenu && open ? "column" : "row",
              height: mobileMenu && open ? "auto" : "55px",
            }}
          >
            {/* Logo */}
            <Logo
              to="/"
              style={{
                display: mobileMenu && open ? "none" : "flex",
              }}
            >
              <img src={logo} alt="Logo" />
            </Logo>
            {/* Page Title */}
            <PageTitle
              className={"foo"}
              id={"page-title"}
              to="/"
              style={{
                marginRight: mobileMenu && open ? "0" : "auto",
                marginTop: mobileMenu && open ? "1rem" : "0",
              }}
            >
              <span>Gau Alt-Burgund</span>
            </PageTitle>
            {/* Page Links */}
            <PageLinkContainer
              id="pagelink-container"
              style={{
                display: !mobileMenu || (mobileMenu && open) ? "flex" : "none",
              }}
            >
              <PageLink to="/pfadfinden">
                <span>Pfadfinden</span>
              </PageLink>

              <MenuElement>
                {/*<PageLink to="/ueber-uns" hasSubmenu={true}>*/}
                <PageLink to="/ueber-uns">
                  <span>Über uns</span>
                </PageLink>
                <HoverCatch className={"none"} />
                <DropdownArrow className={"none"} />
                <Dropdown className={"none"}>
                  <ul>
                    <li className={"last"}>
                      <DropdownLink to="/wer-wir-sind">
                        Wer wir sind
                      </DropdownLink>
                    </li>
                    <li className={"last"}>
                      <DropdownLink to="/vertrauenspersonen">
                        Vertrauenspersonen
                      </DropdownLink>
                    </li>
                    <li className={"first"}>
                      <DropdownLink to="/ueber-uns">Der Gau </DropdownLink>
                    </li>
                  </ul>
                </Dropdown>
              </MenuElement>

              <PageLink to="/staemme-und-siedlungen">
                <span>Stämme und Siedlungen</span>
              </PageLink>

              <MenuElement>
                {/*<PageLink to="/ueber-uns" hasSubmenu={true}>*/}
                <PageLink to="/ueber-uns">
                  <span>Aktionen</span>
                </PageLink>
                <HoverCatch className={"none"} />
                <DropdownArrow className={"none"} />
                <Dropdown className={"none"}>
                  <ul>
                    <li className={"first"}>
                      <DropdownLink to="/bachelor-of-fahrt">
                        Lager und Fahrten
                      </DropdownLink>
                    </li>
                    <li className={"last"}>
                      <DropdownLink to="/fahrtenatlas">
                        Fahrtenatlas
                      </DropdownLink>
                    </li>
                    <li className={"last"}>
                      <DropdownLink to="/termine">Termine</DropdownLink>
                    </li>
                  </ul>
                </Dropdown>
              </MenuElement>

              <PageLink to="/kontakt">
                <span>Kontakt</span>
              </PageLink>
            </PageLinkContainer>

            {/* hamburger icon for mobile */}
            <Hamburger
              onClick={toggleMenu}
              src={hamburger}
              alt={"menu icon"}
              style={{
                display: mobileMenu ? "block" : "none",
              }}
            />
          </NavWrapper>
        </NavStyle>
      </nav>
    </header>
  )
}
