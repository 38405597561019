import React from 'react';
import {
  FooterContainer,
  FooterLink,
  FooterStyle,
} from './styled';

export default function Footer() {
  return (
    <FooterStyle>
      <FooterContainer>
        <FooterLink to="/impressum">Impressum</FooterLink>
        <FooterLink to="/datenschutz">Datenschutzerklärung</FooterLink>
      </FooterContainer>
    </FooterStyle>
  );
}
