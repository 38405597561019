import styled from 'styled-components';
import { Link } from 'gatsby';
import colors from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';
import dimensions from '../../constants/dimensions';

export const FooterStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${colors.vcp};
  height: ${dimensions.footerHeight}px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  max-width: ${dimensions.maxWidth}px;

  @media (max-width: ${breakpoints.xxSmall}px) {
    flex-direction: column;
  }
`;

export const FooterLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;

  
  color: white;
  text-decoration: none;

  @media (max-width: ${breakpoints.xxSmall}px) {
    width: 100%;
    padding: 1rem 0 1rem 0;
  }

  //Hover
  &:hover,
  &:focus {
    background-color: black;
    color: ${colors.burgund};
  }
`;
